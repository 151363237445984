
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
import ValidationMixin from '@/mixins/ValidationMixin.vue'
import { stringUtils } from '@/utils/string'
import moment from 'moment'
import { Http } from '@/apis/Http'
import Moderation from '@/apis/Moderation'

@Component({
  name: 'AdminModeration',
  components: {
  }
})

export default class AdminModeration extends Mixins(ValidationMixin, TableMixin) {
  simpleTable = true
  isLoading = true
  dealershipsFile = null
  managersFile = null

  requestsList = {
    headers: [
      {
        text: 'Номер',
        align: 'start',
        value: 'id'
      },
      {
        text: 'Дата',
        align: 'start',
        value: 'date'
      },
      {
        text: 'Тип запроса',
        align: 'start',
        value: 'requestType'
      },
      {
        text: 'Инициатор',
        align: 'start',
        value: 'user'
      },
      {
        text: 'Email',
        align: 'start',
        value: 'email'
      },
      {
        text: 'Телефон',
        align: 'start',
        value: 'phone'
      },
      {
        text: 'Статус',
        align: 'start',
        value: 'status'
      }
    ],
    items: []
  }

  created() {
    this.getModerations()
  }

  async getModerations() {
    await Moderation.fetchAll()
      .then(({ data }) => {
        this.requestsList.items = data.data
        this.isLoading = false
      })
  }

  sendDealershipsFile() {
    const formData = new FormData
    // @ts-ignore
    formData.append('dealershipsFile', this.dealershipsFile)
    Http.post('admin/moderations/load-dealerships', formData, { timeout: 1800000 })
      .then(response => {
        console.log(response.data)
        if (response.data.success) {
          // @ts-ignore
          this.showSuccessSystemNotification(response.data.msg)
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(response.data.msg)
        }
      })
      .catch((errors) => {
        // @ts-ignore
        this.showErrorSystemNotification(errors)
      })
  }

  sendManagersFile() {
    const formData = new FormData
    // @ts-ignore
    formData.append('managersFile', this.managersFile)
    Http.post('admin/moderations/load-managers', formData)
      .then(response => {
        console.log(response.data)
        if (response.data.success) {
          // @ts-ignore
          this.showSuccessSystemNotification(response.data.msg)
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(response.data.msg)
        }
      })
      .catch((errors) => {
        // @ts-ignore
        this.showErrorSystemNotification(errors)
      })
  }

  sendLeasingFile() {
    const formData = new FormData
    // @ts-ignore
    formData.append('leasingFile', this.leasingFile)
    Http.post('admin/moderations/load-leasing', formData, { timeout: 1800000 })
        .then(response => {
          console.log(response.data)
          if (response.data.success) {
            // @ts-ignore
            this.showSuccessSystemNotification(response.data.msg)
          } else {
            // @ts-ignore
            this.showErrorSystemNotification(response.data.msg)
          }
        })
        .catch((errors) => {
          // @ts-ignore
          this.showErrorSystemNotification(errors)
        })
  }

  get search() {
    return this.$store.state.common.searchAdminModerations
  }

  set search(item) {
    this.$store.state.common.searchAdminModerations = item
  }

  formatDate(date: Date): string {
    return moment(date).format('LLL')
  }

  countOrders(number: number): string {
    return stringUtils.pluralForm(number, ['запрос', 'запроса', 'запросов'])
  }
}
